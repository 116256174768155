import * as React from "react";
import { Link } from "@reach/router";
import { ReactNode } from "react";
import Home from "./pages/Home";
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarMenu,
  NavbarStart,
  NavbarItem
} from "bloomer";

import "./Navigation.scss";

interface Props {
  children: ReactNode;
}

const Navigation = () => {
  return (
    <Navbar className="Navigation">
      <Container>
        <NavbarBrand>
          <NavbarItem>Thecoil dashboard</NavbarItem>
        </NavbarBrand>
        <NavbarMenu>
          <NavbarStart>
            <Link to="/" className="navbar-item">
              Home
            </Link>
            <Link to="/posts" className="navbar-item">
              Posts
            </Link>
            <Link to="/photos" className="navbar-item">
              Photos
            </Link>
            <Link to="/users" className="navbar-item">
              Users
            </Link>
          </NavbarStart>
        </NavbarMenu>
      </Container>
    </Navbar>
  );
};

export default Navigation;
