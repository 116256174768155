import gql from "graphql-tag";

export const CREATE_CHECKOUT_CART = gql`
  mutation {
    checkoutCreate(input: {}) {
      userErrors {
        message
        field
      }
      checkout {
        id
        webUrl
        subtotalPrice
        totalTax 
        totalPrice
        lineItems (first:250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              title
              variant {
                id
                title
                image {
                  src
                }
                price
              }
              quantity
            }
          }
        }
      }
    }
  }
`;


export const ADD_VARIANT_TO_CART = gql`
  mutation ($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(
      checkoutId: $checkoutId,
      lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        id
        webUrl
        subtotalPrice
        totalTax
        totalPrice
        lineItems (first:250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              title
              variant {
                id
                title
                image {
                  src
                }
                price
              }
              quantity
            }
          }
        }
      }
    }
  }
 `;
