import * as React from "react";
import { ReactNode } from "react";
import { Container, Title } from "bloomer";

interface Props {
  title: string;
  children: ReactNode;
}

const Section = ({ title, children }: Props) => {
  return (
    <Container className="container">
      <Title isSize={2}>{title}</Title>
      <hr />

      {children}
    </Container>
  );
};

export default Section;
