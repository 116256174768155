import * as React from "react";
import { FC, ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { atom, useAtom } from "jotai";

import { GET_PRODUCTS_BY_COLLECTION } from  '../../apollo/queries/Products'
import { CREATE_CHECKOUT_CART, ADD_VARIANT_TO_CART } from  '../../apollo/mutation/Cart'
import {
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
} from  '../../apollo/mutation/Checkout'
import Page from "../templates/Page";
import Logo from "../../assets/logo.svg";
// import Search from "../../assets/search.svg";
import Cart from "../../assets/cart.svg";
import Espresso from "../../assets/espresso.svg";
import Filter from "../../assets/filter.svg";
import Goods from "../../assets/goods.svg";
import Misc from "../../assets/misc.svg";
import Back from "../../assets/back.svg";
import CloseCircle from "../../assets/close-circle.svg";


interface ProductProps {
  node: {
    title: string;
    description: string;
    images: {
      edges: ImageInterface[]
    };
    variants: {
      edges: VariantInterface[];
      }
  }
}

interface ImageInterface {
  node: {
    src: string;
  }
}

interface VariantInterface {
  node: {
    title: string;
    selectedOptions?: VarianOptions[];
    image: {src: string}[];
    price: number;
  }
}

interface VarianOptions {
  name: string;
  value: string;
}

interface ProductsProps {
  products: ProductProps[];
}

interface ItemsInterface {
  title?: string;
  variant: VariantInterface[]
  quantity?: number;
  node: any;
}
interface CartInterface {
  id: string;
  subtotalPrice: number;
  totalTax: number;
  totalPrice: number;
  webUrl: string;
  lineItems?: {
    edges?: ItemsInterface[]
  }
}


const currentCategoryAtom = atom<string>("frontpage")

const Categories = () => {
  const CATEGORIES = ["Espresso", "Filter", "Goods", "Misc"]


  const [currentCategory, setCurrentCategory] = useAtom(currentCategoryAtom)

  const categoryIcons = (category: string) => {
    const sanitizedCategory = category.toLowerCase();
    switch(sanitizedCategory) {
      case 'espresso':
        return <Espresso/>;
        break;
      case 'filter':
        return <Filter/>;
        break;
      case 'goods':
        return <Goods/>;
        break;
      case 'misc':
        return <Misc/>;
        break;
    }
  }

  const handleCurrentCategory = (category: string ): void => {
    setCurrentCategory(category)
  }

  return(
    <section className="categories">
      {CATEGORIES.map((category, key) => {
        return(
          <article key={key} className={`category ${currentCategory === category ? 'category--active' : '' }`} onClick={() => handleCurrentCategory(category) }>
            <span className="category__icon">
              {categoryIcons(category)}
            </span>
            <span className="category__title">
              {category}
            </span>
          </article>
        )
      })}
    </section>
  )
}

const cartAtom = atom<CartInterface>({
    id: '',
    subtotalPrice: 0,
    totalTax: 0,
    totalPrice: 0,
    webUrl: "",
    lineItems: { edges: []}
})

const cartPreview = atom<boolean>(false);

const CartPreview: React.FC = () => {

  const [checkoutAtom, setCheckoutAtom] = useAtom(cartAtom)
  const [showCart, setShowCart] = useAtom(cartPreview)
  const checkout = checkoutAtom;

  const [initiateCart, {loading}] = useMutation(CREATE_CHECKOUT_CART, { onCompleted: data => {
    setCheckoutAtom(data.checkoutCreate.checkout)
  } });

  const [lineItemRemoveMutation, {loading: loadingRemoveMutation}
  ] = useMutation(checkoutLineItemsRemove, {onCompleted: data => {
    setCheckoutAtom(data.checkoutLineItemsRemove.checkout)

    if (!checkout.lineItems.edges.length) {
      initiateCart();
    }
  }});

  const [lineItemUpdateMutation, {loading: loadingUpdateMutation}
  ] = useMutation(checkoutLineItemsUpdate, {onCompleted: data => {
    setCheckoutAtom(data.checkoutLineItemsUpdate.checkout)
  }});

  React.useEffect(()=>{
      initiateCart();
  },[])



  return(
    <div className={showCart ? "CartSection" : "CartSection CartSection--hide"}>
      <section className="CartSection__content">
        <article className="CartSection__content__header">
          <Back onClick={() => setShowCart(false)} />
          <h3>COFFEE CART</h3>
        </article>

        {loadingRemoveMutation && <Loading/>}
        {loadingUpdateMutation && <Loading/>}

        <article className="CartSection__content__items">
          <ul>
            {checkout.lineItems && checkout.lineItems.edges.map((item, key) => {
              const product = item.node;
              return(<li key={key}>
                <div className="CartSection__content__items__image">
                  <img src={product.variant.image.src}/>
                </div>
                <div className="CartSection__content__items__description">
                  <div className="CartSection__content__items__description__title">
                    <h5>{product.title}</h5>
                    <div className="CartSection__content__items__description__remove" onClick={()=> lineItemRemoveMutation({
                      variables: {
                        checkoutId: checkout.id,
                        lineItemIds: [product.id]
                      }
                    })}><CloseCircle /></div>
                  </div>
                  <div className="CartSection__content__items__detail">
                    <div className="CartSection__qty">
                      <button className="qty-button" onClick={()=> lineItemUpdateMutation({
                        variables: {
                          checkoutId: checkout.id,
                          lineItems: [
                            {
                              id: product.id,
                              quantity: product.quantity + 1
                            }
                          ]
                        }
                      })}> + </button>
                      <span className="CartSection__value">{product.quantity}</span>
                      <button className="qty-button" onClick={()=> product.quantity >= 1 && lineItemUpdateMutation({
                        variables: {
                          checkoutId: checkout.id,
                          lineItems: [
                            {
                              id: product.id,
                              quantity: product.quantity - 1
                            }
                          ]
                        }
                      })}> - </button>
                    </div>

                    <span className="CartSection__price">{product.variant.price}</span>
                  </div>
                </div>

              </li>)
            })}
          </ul>
        </article>

        <article className="CartSection__content__footer">
          <table>
            <tr>
              <td>
                Subtotal
              </td>
              <td>
                {checkout?.subtotalPrice}
              </td>
            </tr>
            <tr>
              <td>
                Taxes
              </td>
              <td>
                {checkout?.totalTax}
              </td>
            </tr>
            <tr>
              <td>
                Total
              </td>
              <td>
                {checkout?.totalPrice}
              </td>
            </tr>
          </table>
          {checkout.totalPrice != 0 && 
            <a className="CartSection__checkout" href={checkout?.webUrl}>CHECKOUT</a>
          }
        </article>
      </section>
    </div>
  )
}



const Products = ({products}: ProductsProps) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const [productDetail, setProductDetail] = React.useState(null);
  const [quantity, setQuantity] = React.useState(1);

  const handleShowProductDetail = (product): void => {
    setProductDetail(product)
    setShowDetail(true)
  }

  const [checkoutAtom,setCheckoutAtom] = useAtom(cartAtom)

  const [addVariantToCart, {loading, data}] = useMutation(ADD_VARIANT_TO_CART, {
    onError: error => {
      console.log('error', error)

    },
    onCompleted: data => {
      // console.log('oncompleted add variant to cart')
      const checkoutData = data.checkoutLineItemsAdd.checkout;
      setCheckoutAtom(checkoutData);
      setProductDetail(null)
      setShowDetail(false)
  }
  });

  // console.log('checkout atom Products component', checkoutAtom)

  const handleVariantToCart = (variantId: string, quantity: number) => {
    const lineItems = [{variantId, quantity: quantity}];
    const checkoutId = checkoutAtom.id;
    addVariantToCart({
      variables: {
        checkoutId,
        lineItems
      }
    })
  }

  const renderProductDetail = (product): React.ReactNode => {
    const {id, title, description, images} = product;
    const imageUrl: string =images.edges[0].node.src;
    const variantId =  product.variants.edges[0].node.id;
    const price =  product.variants.edges[0].node.price;
    return(
      <section className="productDetail__content">
        <article className="productDetail__header">
          <button className="productDetail__back" onClick={()=> setShowDetail(false)}><Back/></button>
          <img className="productDetail__image" src={imageUrl} alt={title}/>
        </article>
        
        <article className="productDetail__section">
          <h2>{title}</h2>
          <div className="productDetail__price-and-qty">
            <div className="productDetail__price">
              Rp.{price}
            </div>
            <div className="productDetail__qty">
              <button onClick={()=> setQuantity(quantity + 1)}> + </button>
              <span className="productDetail__value">{quantity}</span>
              <button onClick={()=> quantity > 1 && setQuantity(quantity - 1)}> - </button>
            </div>
          </div>
          <p className="productDetail__description">
            {description}
          </p>
          <button className="productDetail__add-to-cart" onClick={()=>handleVariantToCart(variantId, quantity)}>{loading ?  <Loading color="white"/> : "Add to Cart"}</button>
        </article>
      </section>
    )

  }

  return(
    <section className="products">
      <div className={`${showDetail ? 'productDetail productDetail--show' : 'productDetail'}`}>
        {productDetail && renderProductDetail(productDetail)}
      </div>
      {products.map((product: ProductProps, key: number) => {
        const productDetail = product.node;
        const imageUrl: string = product.node.images.edges[0].node.src;
        return(

        <article  className="product" key={key} onClick={() =>handleShowProductDetail(productDetail)}>
            <img src={imageUrl as string} alt={product.node.title as string} />
            <h3>{product.node.title}</h3>
            <span className="price">Rp.{product.node.variants.edges[0].node.price}</span>
          </article>
        )}
      )}
    </section>
  )
}

const CartComponent: React.FC = () => {
  const [checkoutAtom] = useAtom(cartAtom)
  const [showCart, setShowCart] = useAtom(cartPreview)
  const totalItems = checkoutAtom.lineItems.edges.map((p) => p.node.quantity).reduce((a, b) => a + b, 0)
  return (
    <div className="Cart">
      <Cart onClick={()=> setShowCart(!showCart)}/>
      <span className={
          checkoutAtom.lineItems.edges.length ? "Cart__Items" : "Carty__Items Cart__Items--blank" 
          }>
      {totalItems}
    </span>
    </div>
  )
}

const Loading = ({color}:{color?: string}) => (
  <div className="Loading" style={{color: color}}>
    Loading 
    <div className="Loading-Dot one">.
    </div>
    <div className="Loading-Dot two">.
    </div>
    <div className="Loading-Dot three">.
    </div>
  </div>
)

const Home: React.FC = () => {
  const [products, setProducts ] = React.useState([]);
  const [checkoutAtom] = useAtom(cartAtom)
  const [currentCategory] = useAtom(currentCategoryAtom)

  const { loading, error, data } = useQuery(GET_PRODUCTS_BY_COLLECTION, {
    variables:{
      handle: currentCategory
    }}
  );

  React.useEffect(() => {
    if(data) {
      setProducts(data.collectionByHandle.products.edges)
    }
  },[data])

  // <Search onClick={()=>console.log('search')}/>
  return (
    <div >
      <header>
        <Logo/>
        <CartComponent/>
      </header>
      <section>
        <CartPreview />
        <Categories />
        {loading && <Loading />}
        {!loading && data &&
        <Products products={products}/>
        }
      </section>
    </div>
  )
}

export default Home
