import { HttpLink } from "apollo-link-http";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-boost";
import Config from "../config";
import fetch from "unfetch";

const link = new HttpLink({
  uri: Config.apiUrl,
 headers: {
   'X-Shopify-Storefront-Access-Token': 'e9285ea02a2b07c9d933f9638a681b42'
  }
});

const cache = new InMemoryCache();

const ApiClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link
});

export default ApiClient;
