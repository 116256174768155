import * as React from "react";
import { useState, useEffect } from "react";

import { Router } from "@reach/router";
import HomePage from "./components/pages/Home";
import NotFoundPage from "./components/pages/NotFound";

import "./Routes.scss";

const Routes = () => {
  return (
    <Router>
      <HomePage path="/" />
      <NotFoundPage path="*" default />
    </Router>
  );
};

export default Routes;
