import gql from "graphql-tag";

export const GET_PRODUCTS_BY_COLLECTION = gql`
  query($handle: String!) {
    collectionByHandle(handle: $handle) {
        products(first: 50) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              title
              description
              tags
              images(first: 250) {
                edges {
                  node {
                    src
                  }
                }
              }
              variants(first: 250) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  node {
                    id
                    title
                    selectedOptions {
                      name
                      value
                    }
                    image {
                      src
                    }
                    price
                  }
                }
              }
            }
          }
        }
      }
  }
  `
  export const GET_PRODUCTS = gql`
    {
      collections(first:250) {
        edges {
          node {
             id,
             handle,
             title,
             description,
             products(first: 50) {
                edges {
                  node {
                    id
                    title
                    images(first: 250) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
          }
        }
      }
    }
  `
